import React from 'react';

import Flow from './Flow/Flow';

const Flowchart = () => {
  return (
    <div>
        <Flow />
    </div>
  );
}

export default Flowchart;